import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";

import Info from "../components/info/info";
import { AiOutlineFire } from "@react-icons/all-files/ai/AiOutlineFire";
import { AiOutlineBook } from "@react-icons/all-files/ai/AiOutlineBook";
import { AiOutlineAlert } from "@react-icons/all-files/ai/AiOutlineAlert";
import { AiOutlineTeam } from "@react-icons/all-files/ai/AiOutlineTeam";
import { AiOutlineCrown } from "@react-icons/all-files/ai/AiOutlineCrown";
import companyDescription from "../documents/descriptions/companyDescription";

const primaryColor = "#f44f18";

const ValuesPart = () => {
  return (
    <section role="main">
      <Row className={`presentation justify`}>
        <h1 className={"mt-2em"}>Values</h1>
        <p>{companyDescription.valueIntro} </p>
      </Row>
      <Row className={"justify mt-2em"}>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={AiOutlineFire}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={companyDescription.V1.title}
            firstLetterColor={primaryColor}
            description={companyDescription.V1.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={AiOutlineBook}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={companyDescription.V2.title}
            firstLetterColor={primaryColor}
            description={companyDescription.V2.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={AiOutlineAlert}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={companyDescription.V3.title}
            firstLetterColor={primaryColor}
            description={companyDescription.V3.description}
          />
        </Col>
      </Row>
      <Row className={"justify mt-2em mb-2em"}>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={AiOutlineTeam}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={companyDescription.V4.title}
            firstLetterColor={primaryColor}
            description={companyDescription.V4.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={AiOutlineCrown}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={companyDescription.V5.title}
            firstLetterColor={primaryColor}
            description={companyDescription.V5.description}
          />
        </Col>
      </Row>
    </section>
  );
};

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "images/covers/starry-night.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Our Team"
        description="Hope Labs was founded by three friends with similar values and the desire to give back to the community."
      />
      <section role="main">
        <Row className={`imageBox no-margin`}>
          <div className={`imageFilter d-none d-sm-block`}></div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Artistic starry night long exposure"
          />
          <h1 className={`overlayTitle d-none d-sm-block`}>
            {"Discover our Team Ethos"}
          </h1>
        </Row>
        <ValuesPart />
      </section>
    </Layout>
  );
};

export default About;
